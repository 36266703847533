body {
    background-color: #f8f9fc;
    height: auto;
}

.segment-shadow {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e3e6f0;
    border-radius: 2px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15)!important;
    padding: 20px;
}

h3.ui.header .sub.header {
    font-size: 0.9rem
}

.shadow {
    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15)!important
}

.header-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.issue-filter-bar {
    display: flex;
    flex-direction: row;
}

.issue-filter-bar>div {
    margin-right: 14px;
}

/* input search */

.issue-filter-bar>div:last-child {
    margin-right: 0px !important;
    margin-top: -1px;
}

table a {
    color: #12c0dc !important;
}

a:hover {
    color: #0699af !important;
}

.issue-card-container {
    margin-top: 10px;
    padding-left: 10px;
    /* overflow-x: scroll;
    height:80vh; */
}

.issue-nav-container {
    max-height: calc(100vh - 85px);
    min-height: calc(100vh - 85px);
}

.dashboard-feed {
    height: 460px;
    overflow: hidden;
}

.dashboard-feed:hover {
    overflow-y: scroll;
}

.dashboard-feed::-webkit-scrollbar {
    width: 2px;
}

.tester-search-container {
    max-height: calc(100vh);
    min-height: calc(100vh);
}

.issue-list-container {
    max-height: calc(100vh - 87px);
    min-height: calc(100vh - 87px);
    overflow: hidden;
}

.issue-list-container:hover {
    overflow-y: scroll;
    padding-right: 0px;
}

.issue-list-container::-webkit-scrollbar {
    width: 2px;
}

.issue-details-container-scroll::-webkit-scrollbar {
    width: 2px;
}

.comment-container::-webkit-scrollbar {
    width: 2px;
}

.issue-list-header {
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e8eaef;
}

.issue-details-container {
    background-color: #ffffff;
    padding: 20px 20px 10px 30px;
    margin-top: 20px;
}

.issue-details-container-scroll {
    max-height: calc(100vh - 122px);
    min-height: calc(100vh - 122px);
    margin-right: -20px !important;
    padding-right: 22px;
    overflow: hidden;
}

.issue-details-container-scroll:hover {
    overflow-y: scroll;
    padding-right: 20px;
}

.comment-container {
    max-height: calc(100vh - 390px);
    min-height: calc(100vh - 390px);
    overflow: hidden;
    margin-right: -5px;
}

.comment-container:hover {
    overflow-y: scroll;
    margin-right: -2px;
}

.issue-card {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border-radius: 0 !important;
    min-height: 50px;
    padding-top: 10px;
    padding-left: 15px;
    box-shadow: none !important;
    border-bottom: 1px solid #dedede !important;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: #f8f9fc;
}

.issue-card:hover {
    background-color: #ccbfa817;
    border-left: 1px solid #00ada5;
    padding-left: 14px;
}

.issue-card .title {
    color: #474949;
    font-size: 13px;
    line-height: 22px;
    white-space: nowrap;
}

.issue-card .testCycle {
    color: #474949;
    font-size: 10px;
    margin-top: -4px;
}

.active-issue {
    background-color: #9fa0a91f;
    border-right: 1px solid #00ada5;
}

.issue-card .date {
    color: #474949;
    font-size: 10px;
}

.issue-card .bottom {
    margin-top: 1px;
    margin-bottom: 5px;
}

.ui.feed>.event>.content .extra.text {
    max-width: 100% !important;
}

.ui.feed>.event>.content .extra.text {
    font-size: 12px;
    ;
}

.ui.feed>.event>.content .summary {
    font-size: 12px;
}

.padded-container {
    padding-left: 40px;
    padding-right: 40px;
}

.dashboard-issue:hover {
    background-color: #dedede !important;
    min-height: 120px;
    max-height: 120px;
}

.custom-image {
    /* filter: url(filters.svg#grayscale); Firefox 3.5+ */
    filter: none;
    -webkit-filter: grayscale(0);
    -webkit-transform: scale(1.01);
}

.custom-image:hover {
    filter: gray;
    /* IE5+ */
    -webkit-filter: grayscale(1);
    /* Webkit Nightlies & Chrome Canary */
    -webkit-transition: all .5s ease-in-out;
}

.community-profile .statistic {
    /* border: 1px solid #3eccb8 !important; */
    padding: 10px !important;
    min-width: 108px !important;
    border-radius: 200px !important;
}

.km-button--primary {
    background-color: #5C5AA7;
    color: #000;
}

.km-button {
    border-radius: 3px;
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: white;
    height: 36px;
    padding: 0px 16px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    min-width: 70px;
    transition: all 0.3s ease-out;
}

.km-btn-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.km-btn-file-label {
    line-height: 36px;
    cursor: pointer;
}

.sidebarButton {
    position: absolute;
    right: 0px;
    top: 160px;
    z-index: 10;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.chat-list-container {
    max-height: calc(100vh - 250px);
    min-height: calc(100vh - 250px);
    overflow-y: hidden;
}

.chat-list-container:hover {
    overflow-y: scroll;
    padding-right: 0px;
}

.chat-list-container::-webkit-scrollbar {
    width: 2px;
}

/* RE-BRANDING AND FACTORING UGUNCAN 2020 FEB */

/* START:SIDE MENU STYLES */

.side-bar-u {
    padding-top: 14px;
    background-color: #0548a6;
    height: 100%;
    position: fixed;
    width: 60px;
    top: 0px;
    z-index: 100;
}

.sb-menu-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sb-menu-item {
    margin: 10px;
    cursor: pointer;
    color: #ffffff;
}

.sb-menu-item :hover {
    color: #ffab00;
    transition: 0.3s;
}

.sb-menu-item-active {
    color: #ffab00;
}

.to-bottom {
    position: absolute;
    bottom: 0;
    left: 0px;
    background-color: #0545a5;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 60px;
}

.sb-popover {
    border-radius: 2;
    opacity: 1;
    padding: 10;
    background-color: "#58585a"
}

/* END:SIDE MENU STYLES */

/* START:PAGE HEADER */

.page-header {
    background-color: white;
    padding: 20px 0px 20px 20px !important;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.page-title {
    display: flex;
}

.page-icon {
    background-color: #FF5630;
    display: flex;
    margin-right: 10px;
    width: 40px;
    border-radius: 4px;
    padding-top: 7px;
}

.page-icon i {
    color: white;
    font-size: 18px;
    margin: 0px auto !important;
}

.page-header>.column {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
}

.page-actions {
    align-items: center;
    display: flex;
    padding-right: 16px;
}

.header-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.device-list-header>div>h3>i {
    font-size: 34px !important;
    color: #344563;
}

.device-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.page-content {
    margin-top: 18px;
    margin-left: 18px;
    margin-right: 18px;
}

/* END:PAGE HEADER */

/* OVERWRITE */

.ui.primary.button {
    background-color: #0052cc;
}

.ui.primary.button :hover {
    background-color: #172B4D;
}

.device-actions {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
}

.device-actions .icon {
    margin-bottom: 4px
}

/* FLEX */

.space-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fixed-container {
    max-width: 100%;
    width: 900px !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
}

.scenario-card-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
}

.scenario-checkbox {
    transform: scale(1.6);
}

.header-checkbox {
    display: flex;
    margin-right: 10px;
    border-radius: 4px;
    padding-top: 12px;
}

.sb-logo {
    padding-bottom: 26px !important;
    border-bottom: 1px solid #2161c0;
}

.drawer-body {
    overflow: auto;
    margin-bottom: 57px;
    height: calc(100vh - 200px);
}

.drawer-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: center;
}

.dimmer {
    z-index: 9999;
}

.visium-page-content {
    margin-top: 22px;
    margin-left: 20px;
    margin-right: 20px;
}