html { 
    height: 100% 
 }

 body { 
    margin: 0; 
    padding: 0; 
    height: 100% ;
 }

 .side-drawer { 
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    z-index: 102;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
 }

 .side-drawer.open {
    transform: translateX(0);
 }